import Cookies from "js-cookie";
import { flatten } from "lodash";
import { TokenKey } from "./constant";

export const getToken = () => {
  return Cookies.get(TokenKey);
};

export const setToken = (token) => {
  return Cookies.set(TokenKey, token);
};

export const removeToken = () => {
  return Cookies.remove(TokenKey);
};

export const getServerErrors = (error) => {
  if (error?.response && error.response.data && error.response.status !== 500) {
    const response = error.response.data || {};
    const arrays = Object.entries(response).map((value) => value[1]);
    return flatten(arrays);
  }
  return [
    error?.response?.data?.message || error?.message || "Something went wrong.",
  ];
};
