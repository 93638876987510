import React from 'react'
import News from '../news'
import Stock from '../stock'
import Weather from '../weather'

const Content = () => {
  return (
    <div className='main-content pt-[6.9rem] pb-40 bg-antiFlashWhite'>
        <div className='custom-container'>
                <div className='grid grid-cols-3 gap-[34px]'>
                <div className='content_card'>
                   <Weather />
                </div>
               
                <div className='content_card'>
                    <Stock />
                </div>
                <div className='content_card'>
                    <News />
                </div>
                </div>
        </div>
    </div>
  )
}

export default Content