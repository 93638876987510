import axios from "axios";
import { getToken } from "../helpers/util";

const api = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_URL}`,
});

api.interceptors.request.use(
  (config) => {
    const token = getToken();
    if (token) {
      config.headers = config.headers ?? {};
      config.headers[`Authorization`] = "Bearer " + token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (err) => {
    if (err?.response?.status === 401) {
      window.location.replace("/login");
    }

    return Promise.reject(err);
  }
);

export default api;
