import { create } from "zustand";
import { getToken } from "../helpers/util";

const useUserStore = create((set) => ({
  token: getToken(),
  user: null,
  setUser: (user) => set((state) => ({ ...state, user })),
  setToken: (token) => set((state) => ({ ...state, token })),
  reset: () => set({token: null, user: null})
}));

export default useUserStore;
