const latlonList = {
  US: {
    city: "New York",
    lat: 40.7128,
    lon: 74.006,
  },
  IN: {
    city: "Ahmedabad",
    lat: 23.0225,
    lon: 72.5714,
  },
  GB: {
    city: "London",
    lat: 51.5072,
    lon: 0.1276,
  },
  CA: {
    city: "Toronto",
    lat: 43.6532,
    lon: 79.3832,
  },
  FR: {
    city: "Paris",
    lat: 48.8566,
    lon: 2.3522,
  },
  DE: {
    city: "Berlin",
    lat: 52.5200,
    lon: 13.4050,
  },
  AU: {
    city: "Sydney",
    lat: 33.8688,
    lon: 151.2093,
  },
  ES: {
    city: "Madrid",
    lat: 40.4168,
    lon: 3.7038,
  }
};

export default latlonList;
