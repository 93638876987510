import clsx from "clsx";
import React from "react";
import { useQuery } from "react-query";
import news from "../../../src/images/news.png";
import { getNews } from "../../api/requests";
import Style from "../../css/cards.module.css";
import { DEFAULT_COUNTRY } from "../../helpers/constant";
import useUserStore from "../../store/user";
import Loader from "../loader/Loader";

const News = () => {
  const user = useUserStore((state) => state.user);

  const region = user ? user["custom:country"] : DEFAULT_COUNTRY;

  const {
    data: response,
    isLoading,
    isError,
  } = useQuery(["news", region], () => getNews(region));

  return (
    <div>
      <div className="flex items-center gap-5">
        <img src={news} alt="news" className="pt-2" />
        <h3 className="content-title">News</h3>
      </div>
      {isLoading && (
        <div className="flex justify-center">
          <Loader />
        </div>
      )}
      {isError && (
        <div className="flex justify-center">
          <span className="text-red-500">Something went wrong</span>
        </div>
      )}
      {!isLoading && (
        <div className={clsx(Style.leaderInfo)}>
          <ul className="flex flex-col gap-3 mt-6">
            {response?.data?.data?.map((article, index) => (
              <li key={index}>
                <div>
                  <img
                    height={86}
                    width={87}
                    src={article.urlToImage || "/no-image.jpg"}
                    alt={article.author}
                  />
                </div>
                <div className={clsx(Style.content)}>
                  <h4>{article.title}</h4>
                  <p>{article.description}</p>
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default News;
