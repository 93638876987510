import React from 'react'


export const svgLogo = {
    'location': <svg xmlns="http://www.w3.org/2000/svg" width="12" height="15" viewBox="0 0 12 15" fill="none">
        <path d="M6 14.7813L5.71875 14.4375C5.53125 14.1875 0.75 8.59375 0.75 5.25C0.75 2.34375 3.125 -1.90735e-06 6 -1.90735e-06C8.875 -1.90735e-06 11.25 2.375 11.25 5.25C11.25 8.59375 6.46875 14.2188 6.28125 14.4375L6 14.7813ZM6 0.718754C3.5 0.718754 1.5 2.75 1.5 5.21875C1.5 7.84375 4.96875 12.3125 6 13.5937C7.03125 12.3125 10.5 7.875 10.5 5.21875C10.5 2.75 8.5 0.718754 6 0.718754Z" fill="black" />
        <path d="M6 7.25C4.90625 7.25 4 6.34375 4 5.25C4 4.15625 4.90625 3.25 6 3.25C7.09375 3.25 8 4.15625 8 5.25C8 6.34375 7.09375 7.25 6 7.25ZM6 4C5.3125 4 4.75 4.5625 4.75 5.25C4.75 5.9375 5.3125 6.5 6 6.5C6.6875 6.5 7.25 5.9375 7.25 5.25C7.25 4.5625 6.6875 4 6 4Z" fill="black" />
    </svg>,
    'cloudprecipitation': <svg width="28" height="18" viewBox="0 0 28 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M21.557 16.316C22.8399 16.3164 24.0772 15.8404 25.0288 14.9802C25.9805 14.12 26.5788 12.937 26.7076 11.6606C26.8364 10.3843 26.4866 9.10556 25.7259 8.07256C24.9653 7.03955 23.8481 6.32589 22.591 6.07C22.1788 4.73791 21.3972 3.55008 20.3369 2.64441C19.2767 1.73875 17.9813 1.1524 16.6011 0.953461C15.221 0.754518 13.8127 0.951136 12.5399 1.52049C11.267 2.08984 10.1817 3.00857 9.41005 4.17C8.55424 3.89224 7.64839 3.80333 6.75492 3.9094C5.86144 4.01547 5.00158 4.31399 4.2346 4.7844C3.46762 5.25481 2.81174 5.88592 2.31217 6.63424C1.8126 7.38256 1.48122 8.23029 1.34085 9.11903C1.20049 10.0078 1.25448 10.9164 1.49911 11.7822C1.74374 12.6481 2.17319 13.4506 2.75787 14.1345C3.34254 14.8184 4.06854 15.3674 4.88584 15.7436C5.70314 16.1199 6.5923 16.3145 7.49205 16.314" fill="white" />
        <path d="M21.557 17.066C23.0038 17.0507 24.3962 16.5123 25.4769 15.5502C26.5576 14.5881 27.2535 13.2673 27.436 11.832C27.806 8.832 25.736 5.985 22.79 5.346L23.314 5.86999C22.407 3.03099 19.997 0.788995 17.033 0.269995C15.4611 -0.00660719 13.8427 0.179266 12.3744 0.805032C10.9061 1.4308 9.65113 2.46952 8.76199 3.79499L9.60899 3.44999C6.46899 2.48099 2.97399 3.827 1.38299 6.727C-0.133006 9.49199 0.420995 13.064 2.74899 15.206C4.04458 16.3923 5.73438 17.0554 7.49099 17.067C8.457 17.078 8.457 15.578 7.49099 15.567C5.04099 15.54 2.855 13.907 2.207 11.527C1.89824 10.3585 1.98136 9.121 2.44361 8.0043C2.90586 6.8876 3.72171 5.9534 4.766 5.34499C6.124 4.56199 7.71999 4.436 9.20899 4.896C9.54299 4.996 9.87199 4.82199 10.056 4.55099C11.522 2.39999 14.2 1.23699 16.773 1.73999C17.9496 1.97042 19.0429 2.51212 19.939 3.30868C20.8351 4.10524 21.5012 5.1275 21.868 6.26899C21.9028 6.39406 21.9694 6.50801 22.0612 6.59981C22.153 6.69161 22.2669 6.75815 22.392 6.79299C24.645 7.28199 26.242 9.36799 25.953 11.693C25.679 13.902 23.753 15.534 21.553 15.563C20.589 15.576 20.586 17.076 21.553 17.063L21.557 17.066Z" fill="#231815" />
        <path d="M11.72 16.921L10.983 15C10.838 14.622 10.483 14.361 10.06 14.476C9.69903 14.576 9.39003 15.018 9.53603 15.399L10.273 17.319C10.418 17.697 10.773 17.958 11.196 17.843C11.557 17.743 11.866 17.301 11.72 16.92V16.921Z" fill="#2EA7E0" />
        <path d="M15.5159 16.921L14.7789 15C14.6339 14.622 14.2789 14.361 13.8559 14.476C13.4949 14.576 13.1859 15.018 13.3319 15.399L14.0689 17.319C14.2139 17.697 14.5689 17.958 14.9919 17.843C15.3529 17.743 15.6619 17.301 15.5159 16.92V16.921Z" fill="#2EA7E0" />
        <path d="M19.3118 16.921L18.5748 15C18.4298 14.622 18.0748 14.361 17.6518 14.476C17.2908 14.576 16.9818 15.018 17.1278 15.399L17.8648 17.319C18.0098 17.697 18.3648 17.958 18.7878 17.843C19.1488 17.743 19.4578 17.301 19.3118 16.92V16.921Z" fill="#2EA7E0" />
    </svg>,
    'downArrow': <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
        <g clip-path="url(#clip0_12_5364)">
            <path d="M17.9999 10.5C17.9999 10.3011 17.9209 10.1103 17.7802 9.96967C17.6396 9.82902 17.4488 9.75 17.2499 9.75C17.051 9.75 16.8602 9.82902 16.7196 9.96967C16.5789 10.1103 16.4999 10.3011 16.4999 10.5V12.4425L10.2824 6.2175C10.2127 6.1472 10.1297 6.09141 10.0383 6.05333C9.94693 6.01525 9.84891 5.99565 9.7499 5.99565C9.65089 5.99565 9.55286 6.01525 9.46146 6.05333C9.37007 6.09141 9.28712 6.1472 9.2174 6.2175L6.7499 8.6925L1.2824 3.2175C1.14117 3.07627 0.949621 2.99693 0.749895 2.99693C0.550169 2.99693 0.358623 3.07627 0.217395 3.2175C0.0761671 3.35873 -0.00317383 3.55027 -0.00317383 3.75C-0.00317383 3.94972 0.0761671 4.14127 0.217395 4.2825L6.2174 10.2825C6.28712 10.3528 6.37007 10.4086 6.46146 10.4467C6.55286 10.4847 6.65089 10.5043 6.7499 10.5043C6.8489 10.5043 6.94693 10.4847 7.03833 10.4467C7.12972 10.4086 7.21267 10.3528 7.2824 10.2825L9.7499 7.8075L15.4424 13.5H13.4999C13.301 13.5 13.1102 13.579 12.9696 13.7197C12.8289 13.8603 12.7499 14.0511 12.7499 14.25C12.7499 14.4489 12.8289 14.6397 12.9696 14.7803C13.1102 14.921 13.301 15 13.4999 15C17.5424 15 17.3399 15 17.5349 14.94C18.1649 14.685 17.9999 14.3775 17.9999 10.5Z" fill="#FF3737" />
        </g> <defs> <clipPath id="clip0_12_5364"> <rect width="18" height="18" fill="white" /> </clipPath>
        </defs>  </svg>,
    'upArrow': <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
    <g clip-path="url(#clip0_12_5354)">
    <path d="M10.4999 -3.8147e-06C10.301 -3.8147e-06 10.1102 0.0790119 9.96957 0.219666C9.82891 0.360317 9.7499 0.551083 9.7499 0.749996C9.7499 0.948908 9.82891 1.13968 9.96957 1.28033C10.1102 1.42098 10.301 1.5 10.4999 1.5H12.4424L6.2174 7.7175C6.1471 7.78722 6.0913 7.87017 6.05323 7.96156C6.01515 8.05296 5.99555 8.15099 5.99555 8.25C5.99555 8.34901 6.01515 8.44703 6.05323 8.53843C6.0913 8.62982 6.1471 8.71278 6.2174 8.7825L8.6924 11.25L3.21739 16.7175C3.07617 16.8587 2.99683 17.0503 2.99683 17.25C2.99683 17.4497 3.07617 17.6413 3.21739 17.7825C3.35862 17.9237 3.55017 18.0031 3.7499 18.0031C3.94962 18.0031 4.14117 17.9237 4.2824 17.7825L10.2824 11.7825C10.3527 11.7128 10.4085 11.6298 10.4466 11.5384C10.4846 11.447 10.5042 11.349 10.5042 11.25C10.5042 11.151 10.4846 11.053 10.4466 10.9616C10.4085 10.8702 10.3527 10.7872 10.2824 10.7175L7.8074 8.25L13.4999 2.5575V4.5C13.4999 4.69891 13.5789 4.88968 13.7196 5.03033C13.8602 5.17098 14.051 5.25 14.2499 5.25C14.4488 5.25 14.6396 5.17098 14.7802 5.03033C14.9209 4.88968 14.9999 4.69891 14.9999 4.5C14.9999 0.457497 14.9999 0.659996 14.9399 0.464996C14.6849 -0.165003 14.3774 -3.8147e-06 10.4999 -3.8147e-06Z" fill="#34A353"/>
    </g>  <defs> <clipPath id="clip0_12_5354"> <rect width="18" height="18" fill="white" transform="matrix(0 -1 1 0 0 18)"/> </clipPath>
    </defs>
    </svg>,


}

export default svgLogo