import api from "./index";

export const login = (data) => {
  return api.post("login", data);
};

export const signup = (data) => {
  return api.post("signup", data);
};

export const me = () => {
  return api.get("me");
};

export const getWeather = (lat, lon) => {
  return api.post("weather", { lat, lon });
};

export const getFinance = (region) => {
  return api.post("finance", { region });
};

export const getNews = (region) => {
  return api.post("news", { region });
};
