import clsx from "clsx";
import React from "react";
import { useQuery } from "react-query";
import stock from "../../../src/images/stock.png";
import svgLogo from "../../api/data";
import { getFinance } from "../../api/requests";
import Style from "../../css/cards.module.css";
import { DEFAULT_COUNTRY } from "../../helpers/constant";
import useUserStore from "../../store/user";
import Loader from "../loader/Loader";

const Stock = () => {
  const user = useUserStore((state) => state.user);
  const region = user ? user["custom:country"] : DEFAULT_COUNTRY;

  const { data: response, isLoading, isError } = useQuery(["finance", region], () =>
    getFinance(region)
  );

  return (
    <div>
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-5">
          <img src={stock} alt="stock" className="pt-2" />
          <h3 className="content-title">Stock</h3>
        </div>

        {/* <div className="flex items-center gap-[9px]">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="10"
            height="13"
            viewBox="0 0 10 13"
            fill="none"
          >
            <path
              d="M5 12.0039C2.23828 12.0039 0 9.76562 0 7C0 4.23438 2.23828 1.99609 5 1.99609V0.28125L8.75 2.78125L5 4.95703V2.77734C2.67188 2.77734 0.777344 4.67188 0.777344 7C0.777344 9.32812 2.67188 11.2227 5 11.2227C7.32812 11.2227 9.22266 9.34375 9.22266 7H10C10 9.77344 7.76172 12.0039 5 12.0039Z"
              fill="#3E3E3E"
            />
          </svg>
          <p className="text-sm leading-[17px] text-black font-medium">
            Last Updated at 4:54 EST
          </p>
        </div> */}
      </div>
      {isLoading && (
        <div className="flex justify-center">
          <Loader />
        </div>
      )}
      {isError && (
        <div className="flex justify-center">
          <span className="text-red-500">Something went wrong</span>
        </div>
      )}
      {!isLoading && (
        <div className={clsx(Style.stockList)}>
          <ul className="flex gap-5 flex-col mt-6">
            {response?.data?.data?.map((ticker) => (
              <li key={ticker.symbol}>
                <span>{ticker.symbol}</span>
                <p>{ticker.regularMarketPrice?.fmt}</p>
                <div className={clsx(Style.rates, "flex items-center gap-2")}>
                  <div
                    className={clsx(
                      ticker.regularMarketChange?.raw > 0
                        ? Style.green
                        : Style.red,
                      "flex flex-col gap-1  items-end"
                    )}
                  >
                    <h4>{ticker.regularMarketChange?.fmt}</h4>
                    <h5>{ticker.regularMarketChangePercent?.fmt}</h5>
                  </div>
                  <div className="mt-3">
                    {ticker.regularMarketChange?.raw > 0
                      ? svgLogo.upArrow
                      : svgLogo.downArrow}
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default Stock;
