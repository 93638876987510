import clsx from "clsx";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import styles from "../../../src/css/header.module.css";
import { removeToken } from "../../helpers/util";
import useUserStore from "../../store/user";

const Header = () => {
  const navigate = useNavigate();

  const token = useUserStore((state) => state.token);
  const reset = useUserStore((state) => state.reset);

  const signout = () => {
    removeToken();
    reset();
    navigate("/login");
  };

  return (
    <div className="main">
      <header className={clsx(styles.headermain)}>
        <div className="custom-container mx-auto">
          <div className="py-8 flex justify-between items-center">
            <div>
              <Link
                to="/"
                className="uppercase text-black leading-[34px] text-[28px] font-medium"
              >
                Logo
              </Link>
            </div>
            {!token && (
              <div>
                <Link
                  to="/login"
                  className="py-[10px] px-9 bg-bluePigment hover:bg-blue-700 font-semibold transition text-base text-white rounded-[4px]"
                >
                  Sign In
                </Link>
              </div>
            )}
            {token && (
              <div>
                <button
                  onClick={signout}
                  className="py-[10px] px-9 bg-bluePigment hover:bg-blue-700 font-semibold transition text-base text-white rounded-[4px]"
                >
                  Sign Out
                </button>
              </div>
            )}
          </div>
        </div>
      </header>
    </div>
  );
};

export default Header;
