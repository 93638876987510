import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { Link, useNavigate } from "react-router-dom";
import { signup } from "../../api/requests";
import { getServerErrors } from "../../helpers/util";

const Signup = () => {
  const navigate = useNavigate();
  const { register, handleSubmit } = useForm();

  const { mutate, error, isLoading } = useMutation((data) => signup(data), {
    onSuccess: (res) => {
      navigate("/login");
    },
  });

  const onSubmit = (data) => {
    mutate(data);
  };

  return (
    <div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="w-full max-w-md space-y-8">
        <div>
          <img
            className="mx-auto h-12 w-auto"
            src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
            alt="Your Company"
          />
          <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
            Sign up
          </h2>
        </div>
        <form className="mt-8 space-y-6" onSubmit={handleSubmit(onSubmit)}>
          <input type="hidden" name="remember" defaultValue="true" />
          <div className=" rounded-md shadow-sm">
            <div className="mb-2">
              <label htmlFor="email-address" className="sr-only">
                Name
              </label>
              <input
                name="name"
                type="name"
                autoComplete="email"
                required
                className="relative block w-full appearance-none rounded-none rounded-t-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                placeholder="Name"
                {...register("name")}
              />
            </div>
            <div className="mb-2">
              <label htmlFor="email-address" className="sr-only">
                Email address
              </label>
              <input
                id="email-address"
                name="email"
                type="email"
                autoComplete="email"
                required
                className="relative block w-full appearance-none rounded-none rounded-t-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                placeholder="Email address"
                {...register("email")}
              />
            </div>
            <div className="mb-2">
              <label htmlFor="password" className="sr-only">
                Password
              </label>
              <input
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                required
                className="relative block w-full appearance-none rounded-none rounded-b-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                placeholder="Password"
                {...register("password")}
              />
            </div>
            <div className="mb-2">
              <label htmlFor="password" className="sr-only">
                Country
              </label>
              <select
                id="countries"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                {...register("country")}
                required
                name="country"
              >
                <option selected value={""}>
                  Choose a country
                </option>
                <option value="US">United States</option>
                <option value="IN">India</option>
                <option value="GB">United Kingdom</option>
                <option value="CA">Canada</option>
                <option value="FR">France</option>
                <option value="DE">Germany</option>
                <option value="AU">Australia</option>
                <option value="ES">Spain</option>
              </select>
            </div>
          </div>

          <div>
            <button
              disabled={isLoading}
              type="submit"
              className="group relative flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              <span className="absolute inset-y-0 left-0 flex items-center pl-3"></span>
              {isLoading ? " Signing up ..." : "Sign up"}
            </button>
          </div>
          <div>
            <Link
              to={"/login"}
              className="group relative flex w-full justify-center rounded-md border border-transparent  px-4 text-sm font-medium text-indigo-600 focus:outline-none "
            >
              Sign in
            </Link>
          </div>
        </form>
        {error && (
          <div>
            {getServerErrors(error).map((_error, index) => (
              <span key={index} className="text-red-400">
                {_error}
              </span>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Signup;
