import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { Link, useNavigate } from "react-router-dom";
import { login } from "../../api/requests";
import { getServerErrors, setToken } from "../../helpers/util";
import useUserStore from "../../store/user";

const Login = () => {
  const navigate = useNavigate();
  const setTokenInStore = useUserStore((state) => state.setToken);
  
  const { mutate, error, isLoading } = useMutation((data) => login(data), {
    onSuccess: (res) => {
      setToken(res.data.id_token);
      setTokenInStore(res.data.id_token);
      navigate("/");
    },
  });

  const { register, handleSubmit } = useForm();
  const onSubmit = (data) => {
    mutate(data);
  };

  return (
    <div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="w-full max-w-md space-y-8">
        <div>
          <Link to={"/"}>
            <img
              className="mx-auto h-12 w-auto"
              src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
              alt="Your Company"
            />
          </Link>
          <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
            Sign in to your account
          </h2>
        </div>
        <form className="mt-8 space-y-6" onSubmit={handleSubmit(onSubmit)}>
          <input type="hidden" name="remember" defaultValue="true" />
          <div className="-space-y-px rounded-md shadow-sm">
            <div className="mb-2">
              <label htmlFor="email-address" className="sr-only">
                Email address
              </label>
              <input
                id="email-address"
                name="email"
                type="email"
                autoComplete="email"
                required
                className="relative block w-full appearance-none rounded-none rounded-t-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                placeholder="Email address"
                {...register("email")}
              />
            </div>
            <div className="mb-2">
              <label htmlFor="password" className="sr-only">
                Password
              </label>
              <input
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                required
                className="relative block w-full appearance-none rounded-none rounded-b-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                placeholder="Password"
                {...register("password")}
              />
            </div>
          </div>

          <div>
            <button
              disabled={isLoading}
              type="submit"
              className="group relative flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              <span className="absolute inset-y-0 left-0 flex items-center pl-3"></span>
              {isLoading ? "Signing in ..." : "Sign in"}
            </button>
          </div>

          <div>
            <Link
              to={"/signup"}
              className="group relative flex w-full justify-center rounded-md border border-transparent  px-4 text-sm font-medium text-indigo-600 focus:outline-none "
            >
              Signup
            </Link>
          </div>
        </form>
        {error && (
          <div>
            {getServerErrors(error).map((_error, index) => (
              <span key={index} className="text-red-400">
                {_error}
              </span>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Login;
