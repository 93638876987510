import clsx from "clsx";
import React from "react";
import cloud from "../../../src/images/cloud.png";
import svgLogo from "../../api/data";
import cloudwind from "../../images/cloudwind.svg";
import Style from "../../css/cards.module.css";
import useUserStore from "../../store/user";
import { DEFAULT_COUNTRY } from "../../helpers/constant";
import { useQuery } from "react-query";
import { getWeather } from "../../api/requests";
import latlong from "../../helpers/latlong";
import Loader from "../loader/Loader";

const Weather = () => {
  const user = useUserStore((state) => state.user);

  const region = user ? user["custom:country"] : DEFAULT_COUNTRY;

  const latLon = latlong[region];

  const { data: response, isLoading, isError } = useQuery(["weather", region], () =>
    getWeather(latLon.lat, latLon.lon)
  );

  const temp = response?.data?.data?.weather?.temp?.cur;

  return (
    <div className="weather-main">
      <div className="flex items-center gap-5">
        <img src={cloud} className="pt-2" alt="cloud" />
        <h3 className="content-title">Weather</h3>
      </div>
      {isLoading && (
        <div className="flex justify-center">
          <Loader />
        </div>
      )}
      {isError && (
        <div className="flex justify-center">
          <span className="text-red-500">Something went wrong</span>
        </div>
      )}
      {!isLoading && (
        <>
          <div className="flex bg-antiflashwhite rounded pt-4 pl-7 pr-14 pb-6 justify-between mt-6">
            <div className="flex flex-col gap-2">
              <div className="flex items-center gap-2">
                {svgLogo.location}
                <h3
                  className={clsx(
                    Style.cityname,
                    "text-base font-medium leading-[19px]"
                  )}
                >
                  {latLon.city}
                </h3>
              </div>
              <div className="text-bluePigment text-[40px] leading-[48px] font-normal pt-1">
                {temp && `${Math.floor(temp)}°c`}
              </div>
              <div className="text-base font-medium leading-[19px]">
                {response?.data?.data?.weather?.main}{" "}
              </div>
            </div>
            <div className="flex flex-col gap-6">
              <div className="text-sm leading-[17px] text-black font-normal">
                Today 12:00 PM
              </div>
              <div className="flex flex-col gap-[5px] pt-2">
                <div>{svgLogo.cloudprecipitation}</div>
                <div className="flex gap-2 items-center ">
                  <span className="text-bluePigment text-base leading-[19px]">
                    2%
                  </span>
                  <p>Precipitation</p>
                </div>
              </div>
              <div className="flex flex-col gap-[5px]">
                <div>
                  <img src={cloudwind} alt="cloudwind" name="cloudwind" />
                </div>
                <div className="flex gap-2 items-center">
                  <span className="text-bluePigment text-base leading-[19px]">
                    23km/hr
                  </span>
                  <p>Winds</p>
                </div>
              </div>
            </div>
          </div>
          <div
            className={clsx(Style.weatherStatus, "bg-lavender mt-5 rounded")}
          >
            <ul className="flex gap-8 justify-center py-10">
              <li>
                <span>22°c</span>
                {svgLogo.cloudprecipitation}
                <p>Sun</p>
              </li>
              <li>
                <span>24°c</span>
                {svgLogo.cloudprecipitation}
                <p>Mon</p>
              </li>
              <li>
                <span>20°c</span>
                {svgLogo.cloudprecipitation}
                <p>Tue</p>
              </li>
              <li>
                <span>26°c</span>
                {svgLogo.cloudprecipitation}
                <p>Wed</p>
              </li>
              <li>
                <span>24°c</span>
                {svgLogo.cloudprecipitation}
                <p>Thu</p>
              </li>
              <li>
                <span>22°c</span>
                {svgLogo.cloudprecipitation}
                <p>Fri</p>
              </li>
              <li>
                <span>20°c</span>
                {svgLogo.cloudprecipitation}
                <p>Sat</p>
              </li>
            </ul>
          </div>
        </>
      )}
    </div>
  );
};

export default Weather;
