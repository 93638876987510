import React from "react";
import { useQuery } from "react-query";
import { me } from "../../api/requests";
import useUserStore from "../../store/user";
import Content from "../content";
import Header from "../header";

export const Home = () => {
  const token = useUserStore((state) => state.token);
  const setUser = useUserStore((state) => state.setUser);

  useQuery(["me", token], me, {
    enabled: !!token,
    onSuccess: (res) => {
      setUser(res.data.user);
    },
  });

  return (
    <div className="main">
      <div className="">
        <Header />
        <Content />
      </div>
    </div>
  );
};

export default Home;
